exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-join-unipus-tsx": () => import("./../../../src/pages/about-us/JoinUnipus.tsx" /* webpackChunkName: "component---src-pages-about-us-join-unipus-tsx" */),
  "component---src-pages-about-us-news-detail-tsx": () => import("./../../../src/pages/about-us/NewsDetail.tsx" /* webpackChunkName: "component---src-pages-about-us-news-detail-tsx" */),
  "component---src-pages-about-us-news-tsx": () => import("./../../../src/pages/about-us/News.tsx" /* webpackChunkName: "component---src-pages-about-us-news-tsx" */),
  "component---src-pages-about-us-responsibility-tsx": () => import("./../../../src/pages/about-us/Responsibility.tsx" /* webpackChunkName: "component---src-pages-about-us-responsibility-tsx" */),
  "component---src-pages-about-us-understand-unipus-tsx": () => import("./../../../src/pages/about-us/UnderstandUnipus.tsx" /* webpackChunkName: "component---src-pages-about-us-understand-unipus-tsx" */),
  "component---src-pages-aigc-service-tsx": () => import("./../../../src/pages/aigcService.tsx" /* webpackChunkName: "component---src-pages-aigc-service-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index-en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-product-culture-tsx": () => import("./../../../src/pages/product/Culture.tsx" /* webpackChunkName: "component---src-pages-product-culture-tsx" */),
  "component---src-pages-product-digital-content-tsx": () => import("./../../../src/pages/product/DigitalContent.tsx" /* webpackChunkName: "component---src-pages-product-digital-content-tsx" */),
  "component---src-pages-product-educational-ecology-tsx": () => import("./../../../src/pages/product/EducationalEcology.tsx" /* webpackChunkName: "component---src-pages-product-educational-ecology-tsx" */),
  "component---src-pages-product-intelligent-software-tsx": () => import("./../../../src/pages/product/IntelligentSoftware.tsx" /* webpackChunkName: "component---src-pages-product-intelligent-software-tsx" */),
  "component---src-pages-product-product-detail-tsx": () => import("./../../../src/pages/product/ProductDetail.tsx" /* webpackChunkName: "component---src-pages-product-product-detail-tsx" */),
  "component---src-pages-product-smart-hardware-tsx": () => import("./../../../src/pages/product/SmartHardware.tsx" /* webpackChunkName: "component---src-pages-product-smart-hardware-tsx" */),
  "component---src-pages-product-technology-service-tsx": () => import("./../../../src/pages/product/TechnologyService.tsx" /* webpackChunkName: "component---src-pages-product-technology-service-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-service-support-customer-service-tsx": () => import("./../../../src/pages/service-support/CustomerService.tsx" /* webpackChunkName: "component---src-pages-service-support-customer-service-tsx" */),
  "component---src-pages-service-support-trial-consultation-tsx": () => import("./../../../src/pages/service-support/TrialConsultation.tsx" /* webpackChunkName: "component---src-pages-service-support-trial-consultation-tsx" */),
  "component---src-pages-solution-adult-edu-tsx": () => import("./../../../src/pages/solution/adult-edu.tsx" /* webpackChunkName: "component---src-pages-solution-adult-edu-tsx" */),
  "component---src-pages-solution-advanced-edu-tsx": () => import("./../../../src/pages/solution/advanced-edu.tsx" /* webpackChunkName: "component---src-pages-solution-advanced-edu-tsx" */),
  "component---src-pages-solution-career-edu-tsx": () => import("./../../../src/pages/solution/career-edu.tsx" /* webpackChunkName: "component---src-pages-solution-career-edu-tsx" */),
  "component---src-pages-solution-case-detail-tsx": () => import("./../../../src/pages/solution/CaseDetail.tsx" /* webpackChunkName: "component---src-pages-solution-case-detail-tsx" */),
  "component---src-pages-solution-foundation-edu-tsx": () => import("./../../../src/pages/solution/foundation-edu.tsx" /* webpackChunkName: "component---src-pages-solution-foundation-edu-tsx" */)
}

